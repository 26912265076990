import palette from './palette'
import createMuiTheme from '@material-ui/core/styles/createMuiTheme'

const theme = createMuiTheme({
  typography: {
    fontFamily: ['Poppins', 'sans-serif'].join(','),
  },
  palette,
  //   typography: {
  //     fontFamily: ['Poppins', 'sans-serif'].join(','),
  //     h1: {
  //       color: '#212121',
  //       fontWeight: 500,
  //       fontSize: '35px',
  //       letterSpacing: '-0.24px',
  //       lineHeight: '40px',
  //     },
  //     h2: {
  //       color: '#212121',

  //       fontWeight: 500,
  //       fontSize: '29px',
  //       letterSpacing: '-0.24px',
  //       lineHeight: '32px',
  //     },
  //     h3: {
  //       color: '#212121',

  //       fontWeight: 500,
  //       fontSize: '24px',
  //       letterSpacing: '-0.06px',
  //       lineHeight: '28px',
  //     },
  //     h4: {
  //       color: '#212121',

  //       fontWeight: 500,
  //       fontSize: '20px',
  //       letterSpacing: '-0.06px',
  //       lineHeight: '24px',
  //     },
  //     h5: {
  //       color: '#212121',

  //       fontWeight: 500,
  //       fontSize: '16px',
  //       letterSpacing: '-0.05px',
  //       lineHeight: '20px',
  //     },
  //     h6: {
  //       color: '#212121',

  //       fontWeight: 500,
  //       fontSize: '14px',
  //       letterSpacing: '-0.05px',
  //       lineHeight: '20px',
  //     },
  //     subtitle1: {
  //       color: '#212121',

  //       fontSize: '16px',
  //       letterSpacing: '-0.05px',
  //       lineHeight: '25px',
  //     },
  //     subtitle2: {
  //       color: palette.text.secondary,
  //       fontWeight: 400,
  //       fontSize: '14px',
  //       letterSpacing: '-0.05px',
  //       lineHeight: '21px',
  //     },
  //     body1: {
  //       color: '#212121',

  //       fontSize: '14px',
  //       letterSpacing: '-0.05px',
  //       lineHeight: '16px',
  //     },
  //     body2: {
  //       color: palette.text.secondary,
  //       fontSize: '12px',
  //       letterSpacing: '-0.04px',
  //       lineHeight: '18px',
  //     },
  //     button: {
  //       color: '#212121',

  //       fontSize: '14px',
  //     },
  //     caption: {
  //       color: palette.text.secondary,
  //       fontSize: '11px',
  //       letterSpacing: '0.33px',
  //       lineHeight: '13px',
  //     },
  //     overline: {
  //       color: palette.text.secondary,
  //       fontSize: '11px',
  //       fontWeight: 500,
  //       letterSpacing: '0.33px',
  //       lineHeight: '13px',
  //       textTransform: 'uppercase',
  //     },
  //   },
  //   overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
})

export default theme
