import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers/'
// import 'assets/styles/app.scss'
import '@ifca-root/react-component/src/assets/styles/app.scss'
import Layout from 'components/Layout/Layout'
import { createBrowserHistory } from 'history'
import React, { useReducer } from 'react'
import { Router } from 'react-router-dom'
import Routes from './Router/Routes'
import AppContext from './Store/AppContext'
import { GlobalInitialState, RootReducer } from './Store/RootReducer'
import { ThemeProvider } from '@material-ui/core/styles'
import theme from '@ifca-root/react-component/src/assets/theme'
export const history = createBrowserHistory()

const App = () => {
  const [globalState, dispatch] = useReducer(RootReducer, GlobalInitialState)

  return (
    <AppContext.Provider value={{ globalState, dispatch }}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Router history={history}>
            <Layout>
              <Routes />
            </Layout>
          </Router>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </AppContext.Provider>
  )
}
export default App
