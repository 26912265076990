import authenticationRoutes from 'containers/Authentication/AuthenticationRoutes'
import dashboardRoutes from 'containers/DashboardModule/DashboardRoutes'
import React from 'react'
import { Redirect } from 'react-router-dom'

const redirectRoute = [
  {
    props: { exact: true, path: '/' },
    component: <Redirect to="/" />,
  },
]

const errorRoute = [
  {
    // props: { exact: true, path: '/' },
    component: <Redirect to="/authentication/404" />,
  },
]

const routes = [
  ...dashboardRoutes,
  // ...authenticationRoutes,
  ...redirectRoute,
  ...errorRoute,
]

export default routes
