import React, { lazy } from 'react'
const Dashboard = lazy(() =>
  import('./Dashboard').then(module => ({
    default: module.Dashboard,
  }))
)

const dashboardRoutes = [
  {
    props: { exact: true, path: '/' },
    component: <Dashboard />,
  },
  {
    props: { exact: true, path: '/dashboard' },
    component: <Dashboard />,
  },
]

export default dashboardRoutes
