import red from '@material-ui/core/colors/red'
import {
  orange,
  blueGrey,
  indigo,
  blue,
  green,
  grey,
} from '@material-ui/core/colors'
const white = '#FFFFFF'
const black = '#000000'

export default {
  black,
  white,
  primary: {
    contrastText: white,
    dark: '#0019D8',
    main: '#0019D8',
    light: '#0019D8',
  },
  secondary: {
    contrastText: white,
    dark: '#FF123F',
    main: '#FF123F',
    light: '#FF123F',
  },
  success: {
    contrastText: white,
    dark: green[900],
    main: green[600],
    light: green[400],
  },
  info: {
    contrastText: white,
    dark: blue[900],
    main: blue[600],
    light: blue[400],
  },
  warning: {
    contrastText: white,
    dark: orange[900],
    main: orange[600],
    light: orange[400],
  },
  error: {
    contrastText: white,
    dark: red[900],
    main: red[600],
    light: red[400],
  },
  text: {
    primary: '#212121',
    secondary: '#878787',
    link: '#2F53E6',
  },
  background: {
    default: '#212121',
    paper: white,
  },
  icon: black,
  divider: grey[200],
}
