import React, { useContext } from 'react'
import { Route, Switch } from 'react-router-dom'
import AppContext from '../Store/AppContext'
import RouteSuspense from './RouteSuspense'

const Routes = () => {
  const { globalState } = useContext(AppContext as any)
  return (
    <Switch>
      {globalState.routes.map((el, index) => {
        return (
          <Route key={index} exact {...el.props}>
            <RouteSuspense>{el.component}</RouteSuspense>
          </Route>
        )
      })}
    </Switch>
  )
}

export default Routes
